* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media (min-width: 900px) {
  html {
    font-size: 20px;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.loading {
  display: flex;
  animation-delay: 1s;
}

.dot {
  position: relative;
  width: 0.5em;
  height: 0.5em;
  margin: 0.2em;
  border-radius: 50%;
  background: #fff;
}

.dot:nth-child(1):before {
  animation-delay: 0.2s;
}

.dot:nth-child(2):before {
  animation-delay: 0.4s;
}

.dot:nth-child(3):before {
  animation-delay: 0.6s;
}

.dot:nth-child(4):before {
  animation-delay: 0.8s;
}

.dot:nth-child(5):before {
  animation-delay: 1s;
}

.dot:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: wave 2s ease-out infinite;
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}
